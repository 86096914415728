@import "../../assets/css/variables";

.navigationLogo {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 86px;

    &__title {
        display: none;
    }

    &__icon {
        display: block;
        width: 30px;
        height: 30px;
    }
}

// RESPONSIVE VIEW

@media screen and (max-width: 767px) {
    .navigationLogo {
        display: none;
    }
}

@media only screen and (min-width: 1340px) {
    .navigationLogo {
        &--expanded {
            justify-content: start;
        }

        &--expanded &__title {
            display: block;
            width: 192px;
            height: 30px;
        }

        &--expanded &__icon {
            display: none;
        }
    }
}