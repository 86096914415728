@import '../../assets/css/variables';

.SubMenuItem {
    display: flex;
    padding: 24px 32px;
    color: $heading--color;
    align-items: center;
    transition: color 0.2s;

    &:not(:last-child) {
        border-bottom: 1px solid $input-border;
    }

    &__icon {
        margin-right: 22px;
    }

    &__icon i {
        font-size: 22px;
    }

    &__title {
        font-weight: 700;
    }

    &__text {
        font-size: 12px;
        color: $text-gray-100;
    }

    &__badge {
        margin-left: auto;
        display: inline-block;
        min-width: 24px;
        padding: 0 6px;
        border-radius: 12px;
        font-weight: 700;
        text-align: center;
        line-height: 24px;

        &--success {
            background: $color--success-bg;
            color: $color--success;
        }

        &--warning {
            background: $color--highlight-bg;
            color: $color--highlight;
        }
    }

    &--active {
        color: $color--primary;
    }

    &--noPadding {
        padding: 0 !important;
    }

    &--collapsible {
        padding-left: 100px;
    }

    &__collapseIcon {
        margin-left: auto;
    }
}

@media screen and (max-width: 767px) {
    .SubMenuItem {
        padding: 18px 24px;

        &--collapsible {
            padding-left: 100px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $input-border;
        }

        &__icon {
            display: flex;
            height: 40px;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            flex: 0 0 40px;
            margin-right: 12px;
            border: 1px solid $input-border;
            background: $color--blue-100;
            color: $color--primary;
        }
    }
}
