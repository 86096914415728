@import '../../assets/css/variables';

.navigation {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 85px;
    background: $color--white;
    border-right: 1px solid $input-border;
    z-index: 10;

    &__list {
        padding: 0 18px 15px 18px;
        height: calc(100% - 86px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__settingsButton {
        display: none;
    }

    &__links > li {
        margin-bottom: 8px;
    }
}

// RESPONSIVE VIEW

@media screen and (max-width: 767px) {
    .navigation {
        box-shadow: 0 0 16px $dropdown-shadow;
        top: unset;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 99999;

        &__list {
            height: auto;
            padding: 15px;
        }

        &__links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__links > li {
            margin-bottom: 0px;
        }

        &__settingsButton {
            display: block;
        }

        &__user {
            display: none;
        }
    }
}

// sidebar__toggle
@media only screen and (min-width: 1340px) {
    .navigation--expanded {
        width: 241px;
    }
}
