@import './variables';
/* font-family: 'Lato', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('LineAwesome.css');

html,
body,
div,
span,
applet,
object,
iframe,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

img,
fieldset,
a img {
    border: none;
}

input[type='text'],
input[type='email'],
input[type='tel'],
textarea {
    -webkit-appearance: none;
}

input[type='submit'],
button {
    cursor: pointer;
}

input[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

textarea {
    overflow: auto;
}

input,
button {
    margin: 0;
    padding: 0;
    border: 0;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
    outline: none;
}

ul,
ol {
    list-style-type: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: $default-color;

    line-height: 1.5;
}

body.no-scroll {
    overflow: hidden;
}

button,
input,
textarea,
select {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: $default-color;

    font-weight: 700;
}

button {
    background: transparent;
    line-height: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: $text-gray-100;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    color: $text-gray-100;
}

input::placeholder,
textarea::placeholder {
    color: $text-gray-100;
}

textarea {
    display: block;
    width: 100%;
    height: 72px;
    background: none;
    border: none;
    resize: none;
}

a {
    color: $color--primary;
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
}

.la {
    font-size: 18px;
}

body {
    @include scrollbar--styles;
}
