@import "../../assets/css/variables";

.backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $backdrop-color;
    z-index: 98;
}