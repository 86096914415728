@import "../../assets/css/variables";

.counter {
    margin-left: auto;
    display: inline-block;
    min-width: 24px;
    padding: 0 6px;
    border-radius: 12px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;

    background: $color--success-bg;
    color: $color--success;
}