@import '../assets/css/variables';

.mainLayout {
    &__center {
        width: 100%;
        padding: 0 0 0 85px;
        background: #f5f5fa;

        margin-left: auto;
        box-sizing: border-box;
        min-height: 100vh;
    }

    &__topHeader {
        padding: 24px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title,
    &__greeting {
        font-size: 20px;
        margin-left: 20px;
        font-weight: 700;
        margin-right: auto;
    }

    &__greeting {
        padding: 0;
        margin: 0;
    }

    &__greetingWrapper {
        margin: 0 20px 0;
        text-align: end;
    }

    &__expandButton {
        display: none;
    }

    &__expandButton--withSubNavigation {
        display: block;
    }

    &__contentWrapper {
        padding: 0 28px 28px;
        display: flex;
        flex-direction: column;
    }

    &__content {
        background: $color--white;
        border-radius: 12px;
    }

    &__termsPolicy {
        margin: auto 80px 10px auto;
    }

    &__helpHint {
        font-size: 12px;
        margin: 0;
    }
}

@media only screen and (min-width: 1340px) {
    .mainLayout {
        &__center--expandedNavigation {
            padding: 0 0 0 241px;
        }

        &__expandButton {
            display: block;
        }

        &__center--withSubNavigation {
            padding: 0 0 0 465px;
        }
    }
}

@media screen and (max-width: 767px) {
    .mainLayout {
        &__center {
            padding: 0 0 100px 0;
        }

        &__topHeader {
            background: $color--white;
            padding: 15px;
            justify-content: space-between !important;
        }

        &__title {
            display: none;
        }

        &__expandButton--withSubNavigation {
            display: none;
        }

        &__contentWrapper {
            padding: 15px 15px 0px 15px;
        }

        &__content {
            background: transparent;
            border-radius: 0;
        }

        &__termsPolicy {
            margin: auto;
        }

        &__greetingWrapper {
            margin-left: auto;
            margin-right: 2px;
        }

        &__helpDeskHint {
            margin-left: auto !important;
            order: 3;
        }
    }
}
