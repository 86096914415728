@import '../../assets/css/animations.scss';

.loader {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.96);
    z-index: 1000;

    &--inner {
        position: static;
        //background-color: transparent;
    }

    &__component {
        animation: pulse-with-scale 1s infinite;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}
