@import '../../assets/css/variables';

.navigationLink {
    display: flex;
    text-align: center;
    padding: 13px;
    color: $text-gray-100;
    border-radius: 8px;
    align-items: center;
    line-height: normal;

    &--active {
        background: $sidemenu--bg-active;
        position: relative;
        color: $color--primary;

        svg {
            path {
                fill: $color--primary;
            }
        }
    }

    &--active:after {
        content: '';
        position: absolute;
        right: -18px;
        width: 2px;
        top: 0;
        bottom: 0;
        background: $color--primary;
    }

    &__title {
        display: none;
        margin-left: 8px;
    }

    i {
        font-size: 24px;
    }

    &__icon {
        position: relative;

        path {
            fill: $text-gray-100;
        }
    }

    &__newSign {
        position: absolute;
        top: -3px;
        bottom: auto;
        right: -3px;
        width: 12px;
        height: 12px;
        background: $color--green-100;
        border: 2px solid white;
        border-radius: 50%;
    }
}

// RESPONSIVE VIEW

@media screen and (max-width: 767px) {
    .navigationLink {
        &--withText {
            .navigationLink__title {
                display: none;
            }
        }

        &--active:after {
            right: 0;
            bottom: unset;
            height: 2px;
            width: 100%;
            top: -15px;
        }
    }
}

@media only screen and (min-width: 1340px) {
    .navigationLink {
        &--withText {
            .navigationLink__title {
                display: block;
            }
        }
    }
}
