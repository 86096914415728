@import '../../assets/css/variables';

.btn {
    display: inline-flex;
    height: 46px;
    padding: 0 28px;
    background: $color--primary;
    border: 1px solid $color--primary-border;
    border-radius: 8px;
    font-weight: 700;
    color: $color--white;
    transition: all 0.2s;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 16px;
    line-height: 14px;

    &--disabled {
        opacity: 0.5;
    }

    &:hover {
        background: $color--primary-hover;
        border-color: $color--primary-hover;
    }

    &[disabled] {
        background: $color--gray-901;
        border-color: $color--gray-901;
        color: $text-gray-100;
        cursor: not-allowed;
    }

    // Light Button
    &__light {
        background: $color--secondary;
        border-color: $color--secondary-hover;
        color: $color--primary;

        &:hover {
            background: $color--gray-918;
            border-color: $color--gray-918;
        }

        &[disabled] {
            background: $color--gray-901;
            border-color: $color--gray-901;
            color: $text-gray-100;
        }
    }

    &--blue {
        min-width: auto;
        width: auto;
        padding: 0 15px;
        background-color: $color--primary;
        color: $color--white;
        text-transform: uppercase;
        font-size: 13px;
        height: 42px;

        &:hover {
            background-color: $color--primary--dark;
        }
    }
    // Icon Button

    &__icon {
        display: flex;
        min-width: 36px;
        height: 36px;
        padding: 0 9px;
        background: $btn-icon-bg;
        border-radius: 6px;
        font-weight: 700;
        color: $btn-icon-color;
        align-items: center;
        justify-content: center;
        gap: 8px;

        &--lightBlue {
            background: $color--secondary;
            border-color: $sidemenu--bg-active;
            color: $color--primary;
            padding: 15px 18px;
            text-transform: uppercase;
            height: auto;
            font-weight: 700;
            font-size: 14px;

            i {
                position: relative;
            }
        }

        &--blue {
            min-width: auto;
            width: auto;
            padding: 0 15px;
            background-color: $color--primary;
            color: $color--white;
            text-transform: uppercase;
            font-size: 13px;
            height: 42px;

            i {
                color: $color--white;
            }

            &:hover:not(:disabled) {
                background-color: $color--primary--dark;
            }
        }

        &--showOnHover {
            opacity: 0.1;
            transition: all 0.15s ease-in;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &--hiddenBg {
            background-color: transparent;
        }
    }

    &--secondary {
        color: $color--primary;
        background-color: $color--secondary;
        border-color: $color--secondary;

        &:hover {
            background: $color--secondary;
            border-color: $color--secondary;
        }
    }

    &--danger {
        background-color: $color--error;
        border-color: $color--error;

        &:hover {
            background-color: $color--error-hover;
            border-color: $color--error;
        }
    }

    &--success {
        background-color: $color--success;
        border-color: $color--success;

        &:hover {
            background-color: $color--success-hover;
            border-color: $color--success;
        }
    }

    &--medium {
        font-size: 13px;
        height: 36px;
    }

    &--small {
        font-size: 13px;
        padding: 0 15px;
        height: 30px;
    }

    &__name {
        margin: 0 8px;
    }

    &--ghost {
        color: $color--primary;
        background-color: $color--white;
        border-color: $color--white;

        &:hover {
            border-color: $color--white;
            background: $color--tertiary;
        }
    }
}

// Responsive

@media screen and (max-width: 767px) {
    .btn {
        font-size: 14px;
    }
}
