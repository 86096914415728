@import '../../assets/css/variables';

.navigationUserLink {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__title {
        display: none;
        color: $text-gray-100;
    }

    &__wrapper {
        position: relative;
        width: 40px;
    }

    &__pic {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: block;
    }

    &__online {
        width: 10px;
        height: 10px;
        background: $color--success;
        position: absolute;
        right: -3px;
        bottom: -3px;
        border-radius: 10px;
        outline: 3px solid $color--white;
    }
}

@media only screen and (min-width: 1340px) {
    .navigationUserLink {
        &--withText {
            width: 241px;
        }

        &--withText &__title {
            display: inline-flex;
            font-weight: 700;
            margin-left: 15px;
        }
    }
}
