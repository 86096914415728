/*
---------------------------------------------
File: Variables & Mixins
---------------------------------------------
*/

$font--family: 'Lato', sans-serif;
// Button Primary
$color--primary: #5e81f4;
$color--primary-hover: #355bb6;
$color--primary-border: #5e81f4;

$color--primary--dark: #2a4b9b;

// btn Secondary Css
$color--secondary: #eff2fe;
$color--secondary-hover: #eff2fe;

// btn-disable

$heading--color: #1c1d21;
$text-gray-100: #8181a5;
$input-border: #f0f0f3;
$default-color: #1c1d21;
$color--white: #fff;
$color--tertiary: #f5f8ff;
$color--tertiary-hover: #e4eaf9;

$color--highlight: #fcb72b;
$color--highlight-hover: #e3a525;
$color--highlight-bg: rgba(244, 190, 94, 0.1);

$color--error: #e3555a;
$color--error-hover: #c9464b;

$color--error-bg: #fff1f1;

$color--success: #1dba8d;
$color--success-hover: #229675;
$color--success-bg: rgba(124, 231, 172, 0.1);
$color--grey--darker: #1f2125;
$color--grey--dark: lighten(#000, 20%); // #333
$color--grey: #6a6b6d;
$color--grey-light: lighten(#000, 60%); // #999
$color--grey-lighter: #c7cad3;
$color--grey--lighter2: #d9d9d9;
$color--grey--lighter3: #f6f8fc;
$color--grey--lighter4: #96a9bc;

$color--cyan: #e0ffff;
$color--purple: #a020f0;
$color--yellow: #ffff00;
$shadow-color: #b5b5b5;
$btn-icon-bg: #e9e9f1;
$btn-icon-color: #8181a5;

// SideMenu Colors

$sidemenu--bg-active: #eff2fe;
$color--blue-100: rgba(94, 129, 244, 0.1);
$dropdown-border: #f0f0f3;
$backdrop-color: rgba(28, 29, 33, 0.35);
$dropdown-shadow: rgba(0, 0, 0, 0.1);

// Leads Color
$table--head-bg: #fbfbfd;

// toggle

$toggle--bg: #f5f5f5;
$toggle--inactive-color: #98a9bc;

$color--pink-100: #ff808b;
$color--green-100: #7ce7ac;
$color--red-100: #d91212;
$color--orange-100: #ff9a19;
$color--orange-200: #f4be5e;
$color--orange-300: #ffbf5a;
$color--green-200: #3fb8af;
$color--green-300: #2ce5f6;

$btn-red: #c1392b;
$btn-red-hover: #ad2c1e !important;

$panel-red: #f44336;

$app--bg: #f5f5fa;
$color--light-blue-3: #8181a5;

$color--gray-901: #f6f6f6;
$color--gray-918: #d7dffd;
$color--gray-300: #ccc;
$color--gray-100: #eee;
$color--gray-200: #fafafa;
$color--gray-400: #f0f0f0;
$card--shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

// DatePicker
$datePicker--header: #f5f5fa;
$datepicker--shadow: rgba(153, 155, 168, 0.1);
$datepicker--lightbg: rgba(94, 129, 244, 0.1);

// select shadow

$select--shadow: 0px 6px 20px rgba(153, 155, 168, 0.1);

$text--margin-p: 0.5em 0;
$text--margin-h2: 1.5em 0 1em;
$text--margin-h3: 1em 0 0.5em;
$text--margin-list: 0.5em 0 1em 2em;

$sendMessageBackground: #f6f5fa;

$auth-title: #476788;
$auth-form-bg: #eff2fe;
$auth-static-bg-color: #4284f3;

@mixin text--styles {
    &__p {
        margin: $text--margin-p;
    }

    &__h3 {
        margin: $text--margin-h3;
    }

    &__h2 {
        margin: $text--margin-h2;
    }
}

@mixin scrollbar--styles {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 0;
    }

    &::-webkit-scrollbar-track {
        box-shadow: 0 0 0;
        background-color: $color--secondary;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color--gray-300;
        outline: none;
        border-radius: 5px;
    }
}

@mixin readMoreFade {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 70px;
    text-align: center;
    color: $color--primary !important;
    font-weight: bold;
    font-size: 16px;
    padding-top: 45px;
    background-image: linear-gradient(to bottom, transparent, white);
    cursor: pointer;
}
