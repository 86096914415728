@import "../../assets/css/variables";

.plusMenu {
    margin-left: 6px;
    position: relative;

    &__dropdown {
        position: absolute;
        top: 46px;
        right: 0;
        width: 286px;
        background: $color--white;
        border-radius: 6px;
        z-index: 99;
    }

}


@media screen and (max-width:767px) {

    .plusMenu {
        order: 3;

        &__dropdown {
            width: 100%;
            position: fixed;
            top: unset;
            bottom: 0;
            left: 0;
            right: 0;
            padding-bottom: 80px;
        }
    }
}