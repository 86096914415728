@import '../../assets/css/variables';

.SettingsSubMenu {
    border: 1px solid $input-border;
    border-radius: 12px;
}

@media screen and (max-width: 767px) {
    .inbox__menu {
        border: 0;
    }
}
