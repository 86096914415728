@import '../../assets/css/variables';

.subNavigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 80px;
    background-color: $color--white;
    display: flex;
    width: 386px;
    border-right: 1px solid $input-border;
    flex-direction: column;
    z-index: 8;

    &__head {
        display: flex;
        padding: 24px 28px;
        border-bottom: 1px solid $input-border;
        align-items: center;
    }

    &__inner {
        display: flex;
        padding: 28px;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;
    }

    &__preview {
        display: flex;
        height: 36px;
        margin-right: 12px;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        flex: 0 0 36px;
    }

    &__userIcon {
        border-radius: 6px;
    }

    &__companyLogo {
        height: 35px;
    }

    &__wrap {
        flex: 1 1 auto;
    }

    &__title {
        padding: 0;
        border: 0;
        font-weight: 700;
        color: $heading--color;
        font-size: 16px;
        line-height: 16px;
    }

    &__text {
        font-size: 12px;
        color: $text-gray-100;
    }

    &__inner {
        overflow: hidden;
        display: flex;
        padding: 28px;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &__section:last-child {
        margin: 0;
    }
}

@media screen and (min-width: 1340px) {
    .subNavigation {
        &__collapseButton {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .subNavigation {
        display: none;
    }
}
