@import "../../assets/css/variables";

.subNavigationPanelMobile {
    background-color: $color--white;
    display: none;
    width: 100%;

    &__headWrapper {
        border-top: 1px solid $input-border;
        padding: 15px;
        flex-direction: row;
        display: none;
    }

    &__head {
        width: 100%;
    }

    &__toggleButton {
        margin-left: 15px;
    }
}


@media screen and (max-width: 767px) {
    .subNavigationPanelMobile {
        display: block;

        &__headWrapper {
            display: flex;
        }

        &__navigation {
            display: block;
            position: fixed;
            z-index: 99999;
            background: $color--white;
            left: 0;
            right: 0;
            top: unset;
            bottom: 0;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            overflow: hidden;
            margin-bottom: 80px;
        }
    }
}