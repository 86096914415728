@keyframes slideDown {
    0% {
        top: -100px;
    }

    50% {
        top: 10px;
    }

    100% {
        top: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        bottom: -100px;
    }

    50% {
        bottom: 10px;
    }

    100% {
        bottom: 0;
    }
}

@keyframes shake {
    0% {
        transform: translate(0, 0) rotate(-1deg) scale(1);
    }

    5%,
    7%,
    9%,
    11%,
    13%,
    15%,
    17%,
    19%,
    21%,
    23% {
        transform: translate(2px, 0) rotate(10deg) scale(1.02);
    }

    6%,
    8%,
    10%,
    12%,
    14%,
    16%,
    18%,
    20%,
    22%,
    24% {
        transform: translate(-2px, 0) rotate(-10deg) scale(1.02);
    }

    30%,
    100% {
        transform: translate(0, 0) rotate(0) scale(1);
    }
}

@keyframes pulse-green {
    0% {
        box-shadow: 0 0 0 0 rgba(102, 187, 106, 0.7);
    }

    70% {
        box-shadow: 0 0 0 0.6rem rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }

    5%,
    50% {
        opacity: 0.8;
    }

    10%,
    45% {
        opacity: 0.65;
    }

    15%,
    40% {
        opacity: 0.5;
    }

    60%,
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes pulse-with-scale {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 1.15, 1.15);
        transform: scale3d(1.15, 1.15, 1.15);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse-with-scale {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 1.15, 1.15);
        transform: scale3d(1.15, 1.15, 1.15);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
