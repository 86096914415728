@import "../../assets/css/variables";

.plusMenuLink {
    display: flex;
    padding: 20px;
    color: $heading--color;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid $dropdown-border;
    }

    &__title {
        font-weight: 700;
    }

    &__icon {
        display: flex;
        height: 36px;
        margin-right: 16px;
        background: $color--blue-100;

        border-radius: 6px;
        color: $color--primary;
        align-items: center;
        justify-content: center;
        flex: 0 0 36px;
    }
}