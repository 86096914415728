@import '../../assets/css/variables';

.search {
    margin-left: auto;
    position: relative;

    &__dropdown {
        position: absolute;
        top: 0;
        right: 0;
        width: 444px;
        z-index: 99;
    }

    &__field {
        position: relative;
    }

    &__input {
        width: 100%;
        height: 36px;
        padding: 0 36px 0 18px;
        border-radius: 6px;
    }

    &__icon {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
    }
}

// responsive

@media screen and (max-width: 767px) {
    .search {
        order: 1;
        margin: 0;

        &__dropdown {
            left: 0;
            width: calc(100vw - 32px);
        }
    }
}
